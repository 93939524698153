<template>
  <div class="tables">
    <div style="margin: 0 0 30px 0">
      <el-button type="primary" @click="addfreight">创建模板</el-button>
      <el-button @click="deleteAll">批量删除</el-button>
    </div>
    <div style="flex: 1; overflow: auto">
      <el-table
        height="100%"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#EBEEF5"
        
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          fixed
        ></el-table-column>

        <el-table-column prop="templateName" label="模板名称" width="width" >
          <template slot-scope="scope">
            <el-link type="primary" @click="getDetail_left(scope.row.id)">{{
              scope.row.templateName
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="billingType" label="计费方式" width="width">
        </el-table-column>
        <el-table-column prop="currency" label="币种" width="width">
        </el-table-column>
        <el-table-column prop="area" label="国家/地区" width="width" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="userName" label="操作人" width="width">
        </el-table-column>
        <el-table-column prop="updateTime" label="操作时间" width="width">
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="width">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="font-size: 13px; margin-right: 25px"
              @click="calculate(scope.row.id)"
              >试算</el-button
            >
            <el-button
              type="text"
              style="font-size: 13px; margin-right: 25px"
              @click="update(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              style="font-size: 13px; margin-right: 25px"
              @click="deleted(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      style="text-align: center; border-top: 1px solid #e4e7ed; padding: 10px"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="500px"
      title="试算"
      :visible.sync="firstBox"
      :close-on-click-modal="false"
    >
      <hr />
      <el-form
        :inline="true"
        :model="firstObj"
        class="demo-form-inline"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="运费模板"> </el-form-item><br/>
        <el-form-item label="目的国家/地区">
          <el-select
            v-model="firstObj.countryCode"
            placeholder=""
            class="rule_select"
            style="width: 200px"
          >
            <el-option
              v-for="item in this.firstboxList"
              :key="item.id"
              :label="item.nameCn"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item><br/>
        <el-form-item label="计费重">
          <el-input
            style="width: 80px"
            v-model="firstObj.weight"
            placeholder=""
            oninput="value=value.replace(/[^\d]/g,'')"
          >
            <span slot="suffix">g</span>
          </el-input>
        </el-form-item><br/>
        <el-form-item label="试算结果">{{ this.calculateresult }}</el-form-item>
      </el-form>
      <div style="margin-left: 68px">
        <el-button
          type="primary"
          @click="startcalculate"
          style="background-color: #006eff"
          >开始试算</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListPage, batchDelete, getdetail,tryCalculateInit,tryCalculate,theDelete } from "@/api/freight";
export default {
  data() {
    return {
      total:'',
      tableData: [],
      pageInfo: {
        pageSize: "",
      },
      firstBox:false,
      firstObj:{
        weight:'',
        countryCode:'',
      },
      firstboxList:[],
      ids: [],
      obj: [],
      multipleSelection: [],
      calculateId:'',
      calculateresult:'',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    addfreight() {
      this.$router.push("/freightAdd");
    },
    async getData() {
      try {
        const res = await getListPage();
        if ((Object.is(res.code), 200)) {
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total
          this.tableData.map(function(a){
            if(a.billingType == 1){
              a.billingType = "重量段"
            }else{
              a.billingType = "首重续重"
            }
          })
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {}
    },
    //表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.ids = [];
      this.multipleSelection.forEach((data) => {
        this.ids.push(data.id);
      });
    },
    //批量删除
    deleteAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先勾选需要批量操作对象");
        return false;
      }
      let data = {
        ids: this.ids,
      };
      batchDelete(data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //详情
    getDetail_left(id) {
      this.$router.push({ name: "freightDetails", query: { id: id } });
    },
    //编辑
    update(id) {
      this.$router.push({ name: "freightDetail", query: { id: id } });
    },
    //试算
    calculate(id) {
      this.calculateId = id;
      tryCalculateInit(id).then((res) => {
        if (res.data.code == 200) {
          this.firstBox = true;
          this.firstboxList = res.data.data;
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //开始试算
    startcalculate(id){
      let data = {
        id : this.calculateId,
        countryCode : this.firstObj.countryCode,
        weight : this.firstObj.weight,
      }
      tryCalculate(data).then((res) => {
        if (res.data.code == 200) {
          this.calculateresult = res.data.data;
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
    //删除
    deleted(id){
      theDelete(id).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
        } else {
          this.$message.warning(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.tables {
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
}
</style>