import request from '@/utils/request.js'
export function getListPage(params) {
    return request({
        url:'/api/freight/template/listPage',
        method:'get',
        params
      
    });
}
//批量操作
export function batchDelete(data) {
    return request({
        url:'/api/freight/template/batchDelete',
        method:'delete',
        data
    });
}
//详情
export function getdetail(id) {
    return request({
        url:'/api/freight/template/detail/' + id,
        method:'get',
      
    });
}
//下拉框
export function tryCalculateInit(id) {
    return request({
        url:'/api/freight/template/tryCalculateInit/' + id,
        method:'get',
      
    });
}
//试算
export function tryCalculate(data) {
    return request({
        url:'/api/freight/template/tryCalculate',
        method:'post',
        data
    });
}
//删除
export function theDelete(id) {
    return request({
        url:'/api/freight/template/delete/' + id,
        method:'delete',
      
    });
}

//编辑模板确认
export function update(data) {
    return request({
       url: "/api/freight/template/update",
       data,
       method: "put"
    })
}